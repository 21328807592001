import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps } from "@emibee/lib-app-common";
import { AccountState, RwfPermission, RwfPrivilege } from "@emibee/lib-shared";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import { ControlNames, controls, Domains, domainView } from "../../core/textResourceScopes";
import { UserDetails, UserDetailsDataKit, UserOverview, UserOverviewDataKit } from "../../data/user";
import { textResources } from "./textResources";
import { KeepAliveOwner } from "../../core/MHClientService";

export enum AccountDetailsTab {
  details,
  actions,
  files,
  filterSettings,
  notificationSettings,
  auctions,
  memberships,
  audit
}

export interface AccountDetailsConcurrentProps extends PageConcurrentPrefetchProps<UserDetails> {
  initTab?: AccountDetailsTab;
  accountId: string;
  legacy?: boolean;
}

export type AccountListConcurrentProps = PageConcurrentPrefetchProps<UserOverview[]>;

export const AccountDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/accDetails",
  displayText: textResources.routeDetailsText,
  icon: <PersonIcon />,
  auth: true,
  privilege: RwfPrivilege.Accounts,
  permission: RwfPermission.full,
  resources: {
    lazy: () => import("./AccountDetailsPage"),
    localeNamespaces: [domainView(Domains.account), controls(ControlNames.fileRef)],
    prefetchGraph: (args?: AccountDetailsConcurrentProps) => ({
      query: UserDetailsDataKit.queries.getAccount,
      args: { id: args?.accountId },
      watchQuery: true
    })
  },
  params: ["accountId", "initTab", "legacy"]
});

export const AccountListRoute = buildRouteWithGraphPrefetch({
  path: "/accList",
  displayText: textResources.routeListText,
  icon: <GroupIcon />,
  auth: true,
  privilege: RwfPrivilege.Accounts,
  permission: RwfPermission.full,
  resources: {
    lazy: () => import("./AccountListPage"),
    localeNamespaces: [domainView(Domains.account)],
    prefetchGraph: () => ({
      query: UserOverviewDataKit.queries.getAccountList,
      args: UserOverviewDataKit.buildMultiSelectFetchMoreArgs(
        "state",
        [AccountState.active, AccountState.created, AccountState.verificationPending, AccountState.deactivated],
        AccountState,
        {}
      ),
      keepAlive: KeepAliveOwner.AccountList,
      cvFetch: true,
      watchQuery: true,
      mode: "allData"
    })
  }
});
