import { buildTextResources4Data, dataKitBuilder } from "@emibee/lib-app-common";
import { DealerDataKit } from "../dealer/Dealer";

import { CarMediaFile, ICarMin, MediaTypes } from "@mh/common";

// export interface Image {
//   isMainImage?: boolean;
//   thumb: string;
//   src: string;
// }

const mediaFileTextResources = buildTextResources4Data<CarMediaFile>({
  scope: "Data",
  namespace: "Car.MediaFile",
  resources: {
    mediaType: "Media Type",
    uri: "Uri",
    name: "Filename",
    v2: "V2 Image"

    // isMainImage: "Main Image",
    // thumb: "Thumbnail",
    // src: "Source",
    // filename: "Filename"
  }
});
export const MediaFileDataKit = dataKitBuilder(mediaFileTextResources)
  .field("mediaType")
  .props({})
  .build();

export const getMainImage = (mediaFiles?: CarMediaFile[]) =>
  (mediaFiles && mediaFiles.find(mf => mf.mediaType === MediaTypes.image))?.uri || undefined;

// const mediaFileTextResources = buildTextResources4Data<CarMediaFile>({
//   scope: "Data",
//   namespace: "Car.MediaFile",
//   resources: {
//     mediaType: "Type",
//     name: "Filename",
//     uri: "Uri"
//     // media: "Media"
//   }
// });

// export const MediaFileDataKit = dataKitBuilder(mediaFileTextResources)
//   .field("media")
//   .props({ complex: ImageDataKit })
//   .build();

// export interface ICarCommon {
//   id: string;
//   vin: string;
//   maker: string;
//   model: string;
//   _dealer: Dealer;
//   images?: Image[];
//   vMainImage?: Image;

//   variant?: string;
//   vintage?: string;
//   mileage?: number;

//   isPublished?: boolean;

//   // createdAt?: string;
//   // updatedAt?: string;
//   createdAt: number;
//   updatedAt?: number;
// }

export const carMinTextResources = buildTextResources4Data<ICarMin>({
  scope: "Data",
  namespace: "Car",
  resources: {
    id: "ID",
    vin: "VIN",
    maker: "Maker",
    model: "Model",
    ownerOrgName: "Organization",
    ownerOrgId: "OrganizationId",
    mediaFiles: "Media Files",

    // images: "Images",
    vMainImage: "Main Image",
    variant: "Variant",
    initialRegistration: "Vintage",
    mileage: "Mileage",
    isPublished: "Published",
    createdBy: "Created By",
    createdAt: "Created At",
    updatedAt: "Updated At"
  }
});
export const CarMinDataKit = dataKitBuilder(carMinTextResources)
  .fieldType("dateTime", "createdAt", "updatedAt")
  .field("vin")
  .props({})
  .virtualField("vMainImage")
  .props({
    valueGetter: row => getMainImage(row.mediaFiles)
  })

  .build();
