import { IAccount } from "@emibee/lib-shared";
import React from "react";

const zxcvbn = require("zxcvbn");

export function buildPasswordScoring(account: Partial<IAccount>) {
  const banned = ["motorhammer"];
  if (account.email) banned.push(account.email);
  if (account.name) banned.push(account.name);
  return (pwd: string): number | undefined => {
    return pwd ? zxcvbn(pwd, banned)?.score : undefined;
  };
}

export function usePasswordScoring(account: Partial<IAccount>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scoreFunction = React.useCallback(buildPasswordScoring(account), [account]);

  return scoreFunction;
}

export function checkRedirectUrl(url: string) {
  url = url.replace("motorhammer.de", "motorhammer.com");
  return url;
}
