import {
  buildRouteWithParams,
  buildTextResources,
  ButtonWithForm,
  Checkbox,
  Link,
  PageConcurrentResources,
  SignInPageFormControl,
  TextField,
  useRoutingApi
} from "@emibee/lib-app-common";
import { UIPrivilegeMH } from "@mh/common";
import Box from "@mui/material/Box";
import React from "react";
import { DashboardRoute } from "../../common/routes";
import { renderError } from "../../controls/commonControls";
import { SimplePageControl } from "../../controls/SimplePageControl";
import { MHClientService } from "../../core/MHClientService";
import { core, pages } from "../../core/textResourceScopes";
import { makeStyles } from "../../Theme";
import { checkRedirectUrl } from "./common";
import { AccountPages_SignIn } from "./CommonTextResources";
import { ForgotPasswordRoute, SignUpRoute } from "./routes";

export const SignInPageConcurrent: PageConcurrentResources = {
  component: SignInPage,
  localeNamespaces: [pages(AccountPages_SignIn), core()]
};

export const SignInRoute = buildRouteWithParams<SignInPageProps>({
  path: "/signin",
  resources: SignInPageConcurrent,
  params: ["email", "redirect"]
});

const useStyles = makeStyles()(theme => ({
  submit: {
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0, 2)
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 0, 2)
    }
  }
}));

interface SignInFormValues {
  nameOrEmail: string;
  password: string;
  remember: boolean;
}

export const textResources = buildTextResources({
  scope: "Pages",
  namespace: AccountPages_SignIn,
  resources: {
    nameOrEmail: "Username / Email",
    password: "Password",
    rememberMe: "Remember me",
    signUp: "Don't have an account? Sign Up",
    forgotPassword: "Forgot password?",
    formHeader: "Sign in",
    formSubmitButton: "Sign in",
    txtAutoSignedOut: "You have been signed out due to inactivity."
  }
});

export interface SignInPageProps {
  email?: string;
  redirect?: string;
}

export default function SignInPage(props: SignInPageProps) {
  const { email, redirect } = props;
  const { classes } = useStyles();

  // console.log("onSubmitSucceded: red", redirect);

  const routingApi = useRoutingApi();

  const handleLoggedIn = (clientService: MHClientService) => {
    if (!clientService.authorize(UIPrivilegeMH.AppV2) && redirect && clientService.user?.account.confirmed) {
      window.location.replace(checkRedirectUrl(redirect));
    } else if (routingApi.current().definition.path !== SignInRoute.path) {
      routingApi.reload();
    } else {
      routingApi.activateRoute(DashboardRoute);
    }
  };

  const start = Date.now();

  React.useEffect(() => {
    console.log("SignInPage.rendered:", Date.now() - start, "ms", redirect);
  });
  return (
    <SignInPageFormControl
      initialState={{ nameOrEmail: email }}
      onLoad={controlApi => {
        if (controlApi.clientService.signedIn) {
          handleLoggedIn(controlApi.clientService as MHClientService);
        }
      }}
      onSubmitSucceeded={controlApi => {
        // console.log("onSubmitSucceded", response?.result);
        handleLoggedIn(controlApi.clientService as MHClientService);
      }}
      render={(controlApi, formProps) => {
        // console.log("SignInPage.Render SignInPageControl");
        const autoSignedOut = controlApi.clientService.autoSignedOut;
        return (
          <SimplePageControl
            maxWidth="xs"
            controlApi={controlApi}
            hideErrorBox
            title={controlApi.localize(textResources.formHeader)}
            infoText={autoSignedOut ? controlApi.localize(textResources.txtAutoSignedOut) : undefined}
            infoType={autoSignedOut ? "info" : undefined}
            leftActionLink={
              <Link
                resKey={textResources.forgotPassword}
                route={{
                  definition: ForgotPasswordRoute,
                  params: { email: controlApi.formApi.formValues().nameOrEmail, redirect }
                }}
              />
            }
            rightActionLink={<Link resKey={textResources.signUp} route={SignUpRoute} />}
          >
            <Box>
              {renderError(controlApi)}
              <TextField
                margin="normal"
                required
                fullWidth
                resDict={textResources}
                name="nameOrEmail"
                autoComplete="username"
                errorsOnSubmitOnly
                disableOnSubmitting
                autoFocus={!email}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                resDict={textResources}
                type="password"
                autoComplete="current-password"
                errorsOnSubmitOnly
                disableOnSubmitting
                autoFocus={!!email}
              />

              <Checkbox resDict={textResources} name="rememberMe" disableOnSubmitting />

              <ButtonWithForm
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                wrapperClassName={classes.submit}
                disableOnSubmitting
                progressOnSubmitting
                resKey={textResources.formSubmitButton}
              />
            </Box>
          </SimplePageControl>
        );
      }}
    />
  );
}
