import { IServicesAccessor, createServiceIdentifier } from "@emibee/lib-app-common";

export interface CoreLocalStorage {
  activeOrgId: string;
}

interface LocalStorageScopeDefinition {
  core: CoreLocalStorage;
}

export type LocalStorageScope = Extract<keyof LocalStorageScopeDefinition, string>;

export class LocalStorageService {
  static id = createServiceIdentifier<LocalStorageService>("LocalStorageService");
  public get id() {
    return LocalStorageService.id.id;
  }
  // private _services?: IServicesAccessor;
  // set services(value: IServicesAccessor) {
  //   this._services = value;
  // }

  readScope<ScopeT extends LocalStorageScope>(scope: ScopeT): Partial<LocalStorageScopeDefinition[ScopeT]> {
    const data = localStorage.getItem(scope);
    if (data) {
      return JSON.parse(data);
    } else return {};
  }

  writeScope<ScopeT extends LocalStorageScope>(scope: ScopeT, data: Partial<LocalStorageScopeDefinition[ScopeT]>) {
    localStorage.setItem(scope, JSON.stringify(data));
  }
}
