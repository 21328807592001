"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataManagementFieldToStringDE = exports.DataManagementField = exports.SignUpDocumentType = exports.taskCommonActionsToStringDE = exports.TaskCommonActionName = exports.TaskNamesMH = exports.TaskObjectRefKindMH = void 0;
var TaskObjectRefKindMH;
(function (TaskObjectRefKindMH) {
    TaskObjectRefKindMH["Account"] = "Account";
    TaskObjectRefKindMH["Organization"] = "Organization";
})(TaskObjectRefKindMH || (exports.TaskObjectRefKindMH = TaskObjectRefKindMH = {}));
var TaskNamesMH;
(function (TaskNamesMH) {
    TaskNamesMH["ConfirmSignUp"] = "ConfirmSignUp";
    TaskNamesMH["RequestSignUpDocuments"] = "RequestSignUpDocuments";
    TaskNamesMH["DataManagement"] = "DataManagement";
    TaskNamesMH["FreeForm"] = "FreeForm";
})(TaskNamesMH || (exports.TaskNamesMH = TaskNamesMH = {}));
// export type TaskActionNamesMH = "reject" | "confirm" | "subtask";
var TaskCommonActionName;
(function (TaskCommonActionName) {
    TaskCommonActionName["accept"] = "accept";
    TaskCommonActionName["confirm"] = "confirm";
    TaskCommonActionName["reject"] = "reject";
    TaskCommonActionName["yes"] = "yes";
    TaskCommonActionName["no"] = "no";
    TaskCommonActionName["ok"] = "ok";
})(TaskCommonActionName || (exports.TaskCommonActionName = TaskCommonActionName = {}));
function taskCommonActionsToStringDE(f) {
    switch (f) {
        case TaskCommonActionName.accept:
            return "akzeptiert";
            break;
        case TaskCommonActionName.confirm:
            return "bestätigt";
            break;
        case TaskCommonActionName.reject:
            return "abgelehnt";
            break;
        case TaskCommonActionName.yes:
            return "Ja";
            break;
        case TaskCommonActionName.no:
            return "Nein";
            break;
        case TaskCommonActionName.ok:
            return "Ok";
            break;
        default:
            return "";
            break;
    }
}
exports.taskCommonActionsToStringDE = taskCommonActionsToStringDE;
var SignUpDocumentType;
(function (SignUpDocumentType) {
    SignUpDocumentType[SignUpDocumentType["registration"] = 0] = "registration";
    SignUpDocumentType[SignUpDocumentType["vatId"] = 1] = "vatId";
    // passportFront,
    // passportBack
})(SignUpDocumentType || (exports.SignUpDocumentType = SignUpDocumentType = {}));
/** *Info:*
 * Für jeden weiteren Eintrag muss ein mapping in DataManagementFieldToStringDE hinzugefügt werden, damit die eMail formuliert werden kann
 */
var DataManagementField;
(function (DataManagementField) {
    DataManagementField[DataManagementField["vatId"] = 0] = "vatId";
    DataManagementField[DataManagementField["street"] = 1] = "street";
})(DataManagementField || (exports.DataManagementField = DataManagementField = {}));
function dataManagementFieldToStringDE(f) {
    switch (f) {
        case DataManagementField.street:
            return "Straße";
            break;
        case DataManagementField.vatId:
            return "Umsatzsteuer ID";
            break;
        default:
            return "";
            break;
    }
}
exports.dataManagementFieldToStringDE = dataManagementFieldToStringDE;
