import {
  buildTextResources,
  CopyToClipoardHoverButton,
  IDataKit,
  isResourceKey,
  isSelectData,
  Localizer,
  mergeProperties,
  OptionsType,
  ResourceKeyReference,
  TextResource,
  useConstant,
  useLocalizationService,
  useLocalize
} from "@emibee/lib-app-common";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  DataGridPro,
  DataGridProProps,
  deDE,
  GridActionsCellItem,
  GridActionsColDef,
  GridApiRef,
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridFooterContainer,
  GridLinkOperator,
  GridLoadIcon,
  GridLocaleText,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridSortDirection,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValidRowModel,
  GridValueFormatterParams,
  LicenseInfo,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import * as React from "react";
import { makeStyles } from "../../Theme";
import { useFormatters } from "../../tools/formatters";
import { getMultiSelectOperators } from "./MultiSelectOperator";

LicenseInfo.setLicenseKey(
  "23d3cdecffe567703b30124755ebbe71Tz03OTA2OSxFPTE3MzIwMjAzMDMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

interface StyleArgs {
  height?: string;
}

const useStyles = makeStyles<StyleArgs>()((theme, { height = "100%" }) => ({
  xGrid: {
    // height: "auto",
    height: height,
    display: "flex",
    marginBottom: theme.spacing(2)
  }
}));

// Export Constants
export const DataGridLoadIcon = GridLoadIcon;

// Export TypeAliases
export type DataGridRowsProp = GridRowsProp;
export type DataGridRowProp<R extends DataGridRowModel = any> = GridRowParams<R>;
export const DataGridActionsCellItem = GridActionsCellItem;

// export type DataGridCellValue = GridCellValue;
export type DataGridCellParams = GridCellParams;
export type DataGridXGridProps = DataGridProProps;
export type DataGridFilterItem = GridFilterItem;
export type DataGridFilterOperator = GridFilterOperator;
export type DataGridFilterInputValueProps = GridFilterInputValueProps;
export type DataGridRowModel = GridValidRowModel;
export const DataGridLinkOperator = GridLinkOperator;

// Export Interfaces
export interface DataGridFilterOptionsInputProps extends DataGridFilterInputValueProps {
  options?: OptionsType;
  label?: string;
  localize?: Localizer;
}
export interface DataGridColumnDef<T extends DataGridRowModel = any> extends GridColDef<T> {
  options?: OptionsType;
}

export interface DataGridFilterModel extends GridFilterModel {
  // options?: OptionsType;
}

export interface DataGridSortModel extends GridSortModel {}

const dataGridTextResources = buildTextResources({
  scope: "Controls",
  namespace: "DataGrid",
  resources: {
    footerTotalLabel: "Total:",
    footerTotalPartText: "of"
  }
});

type ColumnName<T> = Extract<keyof T, string>;

interface MHCellParams<T, FieldT extends keyof T> extends Omit<GridCellParams, "value" | "row" | "formattedValue"> {
  row: T;
  value: T[FieldT];
  // formattedValue?: T[FieldT];
}

interface DataGridColumn<T, FieldT extends keyof T>
  extends Omit<Partial<GridActionsColDef>, "field" | "valueFormatter" | "valueGetter" | "renderCell"> {
  customName?: string;
  valueGetter?: (params: MHCellParams<T, FieldT>) => any;
  valueFormatter?: (value?: T[FieldT]) => any;
  renderCell?: (params: MHCellParams<T, FieldT>) => React.ReactElement;
  hoverCopy?: boolean;
}

interface IDataGridColumnPropsBuilder<T extends DataGridRowModel, FieldT extends keyof T>
  extends IDataGridColumnBuilder<T> {
  props: (opts: DataGridColumn<T, FieldT>) => IDataGridColumnBuilder<T>;
}

export interface DataGridDefinition<T extends DataGridRowModel = any> {
  columns: DataGridColumnDef<T>[];
  filterModel?: DataGridFilterModel;
  sortModel?: DataGridSortModel;
  onFilterModelChange?: (model: GridFilterModel) => void;
  showExportButton?: boolean;
  getRowId?: (row: T) => string | number;
  // dateRangeFilter?: { field: string; preset?: DateRangeFilterPreset };
  customToolbarComponents?: React.ReactNode;
  customFooterComponents?: React.ReactNode;
  getDetailPanelContent?: (params: GridRowParams<T>) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams) => number | "auto";
  getTreeDataPath?: (row: T) => string[];
}

export interface DateRangeFilterPreset {
  middleOfDaysRange?: number;
  latestDays?: number;
  latestDaysTilNow?: number;
  daysFromNow?: number;
  thisMonth?: boolean;
  range?: [Date, Date];
}

export interface IDataGridColumnBuilder<T extends DataGridRowModel = any> {
  initialSort: (column: ColumnName<T>, direction?: GridSortDirection) => IDataGridColumnBuilder<T>;
  idColumn: (column: ColumnName<T>) => IDataGridColumnBuilder<T>;
  // dateRangeFilter: (column: ColumnName<T>, preset?: DateRangeFilterPreset) => IDataGridColumnBuilder<T>;
  selectColumns: (...columns: ColumnName<T>[]) => IDataGridColumnBuilder<T>;
  selectColumnsBL: (...columns: ColumnName<T>[]) => IDataGridColumnBuilder<T>;
  visibleColumns: (...columns: ColumnName<T>[]) => IDataGridColumnBuilder<T>;
  column: <FieldT extends Extract<keyof T, string>>(
    name?: FieldT,
    label?: string
  ) => Pick<IDataGridColumnPropsBuilder<T, FieldT>, "build" | "column" | "props">;
  customColumn: (
    name: string,
    colIndex?: number,
    label?: string | ResourceKeyReference
  ) => Pick<IDataGridColumnPropsBuilder<T, any>, "build" | "column" | "props">;
  build: () => DataGridDefinition<T>;
}
export function buildDataGrid<T extends DataGridRowModel = any>(dataKit: IDataKit<T>, localize: Localizer) {
  let columns: DataGridColumnDef[] | undefined = undefined;
  let customFieldIdx = 1;
  let colBuilder: IDataGridColumnBuilder<T>;
  let pendingCol: DataGridColumnDef | undefined = undefined;
  const sortModel: DataGridSortModel = [];
  let filterModel: DataGridFilterModel | undefined = undefined;
  let getRowId: (row: any) => string | number;
  // let dateRangeFilter: DataGridDefinition["dateRangeFilter"] | undefined = undefined;

  const ensureColumns = () => {
    if (!columns) columns = selectColumns(dataKit, localize);
    return columns;
  };

  // const getColumn = (name: string) => {
  //   const col = ensureColumns().find(col => col.field === name);
  //   if (!col) throw new Error("Column not found: " + name);
  //   else return col;
  // };

  const setColumn = (col: DataGridColumnDef, colIdx?: number) => {
    const cols = ensureColumns();
    if (!col.headerName) {
      delete col.headerName;
    }
    if (!col.renderCell) {
      delete col.renderCell;
    }
    let idx = cols.findIndex(c => c.field === col.field);
    if (idx < 0) {
      idx = cols.push(col);
    } else {
      const colDef = cols[idx];

      cols[idx] = mergeProperties(colDef, col);
      // console.log("setColumn", colDef, "+", col, "=", cols[idx]);
    }

    if (colIdx !== undefined && colIdx !== idx) {
      // move
      const delCol = cols.splice(idx, 1)[0];
      cols.splice(colIdx, 0, delCol);
    }
  };
  const handlePending = () => {
    if (pendingCol) {
      if (!pendingCol.field) throw new Error("Columns with no name and no props are not supported.");

      setColumn(pendingCol);
    }
  };

  const buildDefinition = (): DataGridDefinition => {
    handlePending();
    return {
      columns: ensureColumns(),
      sortModel,
      filterModel,
      getRowId
      // dateRangeFilter
    };
  };

  const handleColumns = (name: string, colIndex?: number, label?: string | ResourceKeyReference) => {
    handlePending();
    pendingCol = {
      field: name as string,
      headerName: localize(label) as string
    };
    return {
      props: (opts: DataGridColumn<T, any>) => {
        pendingCol = undefined;
        const {
          customName,
          headerName = localize(label) as string,
          valueFormatter,
          hoverCopy,
          renderCell,
          ...colDef
        } = opts;
        setColumn(
          {
            ...colDef,
            headerName,
            field: name ?? customName ?? `custom${customFieldIdx++}`,
            renderCell:
              renderCell ?? (hoverCopy ? p => renderHoverCopy(p.value, p.colDef.align === "left") : undefined),
            valueFormatter: valueFormatter ? p => valueFormatter((p.value as any) ?? undefined) : undefined
          } as DataGridColumnDef,
          colIndex
        );

        return colBuilder;
      },
      column: colBuilder.column,
      build: colBuilder.build
    };
  };

  colBuilder = {
    initialSort: (field: ColumnName<T>, sort: GridSortDirection = "asc") => {
      sortModel.push({ field, sort });
      return colBuilder;
    },
    // dateRangeFilter: (field: ColumnName<T>, preset?: DateRangeFilterPreset) => {
    //   dateRangeFilter = { field, preset };
    //   return colBuilder;
    // },
    idColumn: (field: ColumnName<T>) => {
      getRowId = row => row && row[field];
      return colBuilder;
    },
    selectColumns: (...selectedColumns: ColumnName<T>[]) => {
      columns = selectColumns(dataKit, localize, selectedColumns);
      return colBuilder;
    },
    selectColumnsBL: (...blacklistedColumns: ColumnName<T>[]) => {
      columns = selectColumns(dataKit, localize, filterBlacklistDatakit(dataKit, blacklistedColumns));
      return colBuilder;
    },
    visibleColumns: (...visibleColumns: ColumnName<T>[]) => {
      if (!columns) throw new Error("Select columns first before set visibility");
      const vColumns = visibleColumns.map(v => {
        const col = columns!.find(c => c.field === v);
        col!.hide = false;
        return col!;
      });
      const hColumns = columns
        .filter(c => c.hide === undefined)
        .map(c => {
          c.hide = true;
          return c;
        });
      columns = [...vColumns, ...hColumns];
      return colBuilder;
    },
    column: <FieldT extends Extract<keyof T, string>>(name?: FieldT, label?: string | ResourceKeyReference) =>
      handleColumns(name as string, undefined, label),
    customColumn: handleColumns,
    build: buildDefinition
  };

  return colBuilder;
}

export function useDataGridBuilder<T extends {}>(
  dataKit: IDataKit<T>,
  builderFn: (args: {
    builder: IDataGridColumnBuilder<T>;
    localize: Localizer;
    formatters: ReturnType<typeof useFormatters>;
  }) => void
) {
  const localize = useLocalize();
  const formatters = useFormatters();
  return useConstant(() => {
    const builder = buildDataGrid(dataKit, localize);
    builderFn({ builder, localize, formatters });
    return builder.build();
  });
}

function filterBlacklist<ListT, BlackLT>(
  list: ListT[],
  bl: BlackLT[],
  matchFn: (itemL: ListT, itemBl: BlackLT) => boolean
): ListT[] {
  return list.filter(itemL => !bl.some(itemBl => matchFn(itemL, itemBl)));
}

function filterBlacklistDatakit(dataKit: IDataKit<any>, columns: string[]) {
  return filterBlacklist(dataKit.fields, columns, (field, column) => field.name === column).map(field => field.name);
}

function findField(name: string, dataKit: IDataKit<any>) {
  return dataKit.fields.find(f => f.name === name);
}

function selectColumns(dataKit: IDataKit<any>, localize: Localizer | undefined, selection?: string[]) {
  let fields = dataKit.fields;
  if (selection) {
    fields = selection.map(s => findField(s, dataKit)!);
  }
  return fields.map<DataGridColumnDef>(f => {
    const colDef: DataGridColumnDef = {
      field: f.name,
      type: f.type,
      align: !f.type || f.type === "string" ? "left" : f.type === "boolean" ? "center" : "right",
      headerName: localize ? (localize(f.res) as string) : f.res.fallback,
      valueGetter: f.valueGetter ? p => f.valueGetter!(p.row) : undefined,
      valueFormatter: f.valueFormatter
        ? p => f.valueFormatter!(p.value)
        : f.options
        ? OptionsFormatter(f.options, localize)
        : undefined
    };
    if (f.options) {
      colDef.filterOperators = getMultiSelectOperators(f.options, localize);
      colDef.options = f.options;
    }
    if (!colDef.valueGetter && (f.type === "date" || f.type === "dateTime")) {
      colDef.valueGetter = p => (p.value ? new Date(p.value as number) : p.value);
    }
    return colDef;
  });
}

function OptionsFormatter(options: OptionsType, localize: Localizer | undefined) {
  let locOptions: any | undefined = undefined;
  if (isSelectData(options)) {
    locOptions = options.reduce((p, c) => {
      p[c.value.toString()] = localize && isResourceKey(c.label) ? localize(c.label) : c.label ?? c.value;
      return p;
    }, {} as any);
  }
  // return (params: DataGridCellParams) => {
  // Todo: @michi, was anstelle von any?

  return (params: GridValueFormatterParams) => {
    const val = params.value ?? undefined;
    if (locOptions && val !== undefined) {
      if (Array.isArray(val)) {
        return val.map(v => locOptions[v.toString()]);
      } else {
        return locOptions[val.toString()];
      }
    } else {
      return val;
    }
    // console.log("OptionsFormatter", options, val, locOptions);
    // return locOptions && val !== undefined ? locOptions[val.toString()] : val;
  };
}

export interface DataGridPopulateOptionsFilter {
  column: string;
  preset?: any[];
}

export interface DataGridApi {
  quickfilter: (filter: string) => void;
  startEdit: (id: GridRowId, field: string) => void;
  stopEdit: (id: GridRowId, field: string) => void;
}

export interface DataGridProps extends DataGridDefinition {
  rows: DataGridRowsProp;
  onRowClick?: DataGridXGridProps["onRowClick"];
  onCellClick?: DataGridXGridProps["onCellClick"];
  onCellEditStart?: DataGridXGridProps["onCellEditStart"];
  onCellEditCommit?: DataGridXGridProps["onCellEditCommit"];
  loading?: boolean;
  rowHeight?: number;
  maxVisibleEntries?: boolean | number;
  api?: React.MutableRefObject<DataGridApi | undefined>;
}

export function DataGrid(props: DataGridProps) {
  const { filterModel, rows, sortModel, api, onFilterModelChange, maxVisibleEntries, ...rest } = props;
  // const localize = useLocalize();

  const [data, setData] = React.useState<any[]>(() => (Array.isArray(rows) ? rows : []));
  const gridApi = useGridApiRef();

  React.useEffect(() => {
    const data = Array.isArray(rows) ? rows : [];
    // always reset data on row change
    setData(data);
    if (api) {
      // setData(data);
      const cache = data.length > 0 ? new QuickSearchCache(props.columns) : undefined;
      api.current = {
        quickfilter: (filter: string) => {
          cache && setData(cache.filterRows(data, filter));
        },
        startEdit: (id: GridRowId, field: string) => {
          gridApi.current.setCellMode(id, field, "edit");
        },
        stopEdit: (id: GridRowId, field: string) => {
          if (gridApi.current.getCellMode(id, field) === "edit") {
            gridApi.current.commitCellChange({ id, field });
          }

          gridApi.current.setCellMode(id, field, "view");
        }
      };
    }

    // columns should not be changeable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, rows]);

  // locale
  const [localeText, setLocaleText] = React.useState<Partial<GridLocaleText>>();
  const localizationService = useLocalizationService();
  React.useEffect(() => {
    // TODO: Lazyload and more langs
    if (localizationService.activeLang === "de") {
      setLocaleText(deDE.components.MuiDataGrid.defaultProps.localeText);
    } else {
      setLocaleText(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [localeText] = React.useState(() => {
  //   return Object.keys(dataGridTextResources).reduce((p, c) => {
  //     const val = dataGridTextResources[c as keyof typeof dataGridTextResources];
  //     if (val) {
  //       p[c] = localize(val);
  //     } else if (val) {
  //       p[c] = localize(val);
  //     }
  //     return p;
  //   }, {} as any);
  // });

  const [sortModelState, setSortModelState] = React.useState<GridSortModel | undefined>(sortModel);
  const [filterModelState, setFilterModelState] = React.useState<DataGridFilterModel | undefined>(filterModel);

  const handleFilterModelChange = (model: GridFilterModel) => {
    setFilterModelState(model);
    onFilterModelChange && onFilterModelChange(model);
  };

  React.useEffect(() => {
    setFilterModelState(filterModel);
  }, [filterModel]);

  const height = calculateDataGridHeight(data, maxVisibleEntries);
  const { classes } = useStyles({ height });

  return (
    <DataGridPro
      // localeText
      className={classes.xGrid}
      components={{
        Toolbar: () => CustomToolbar({ ...props }, !!filterModelState),
        Footer: props.customFooterComponents ? () => CustomFooter(props.customFooterComponents, gridApi) : undefined
      }}
      getRowId={row => (row.id ? row.id : row._id)}
      {...rest}
      rows={data}
      apiRef={gridApi}
      localeText={localeText}
      disableMultipleSelection
      sortModel={sortModelState}
      onSortModelChange={params => {
        setSortModelState(params);
      }}
      filterModel={filterModelState}
      onFilterModelChange={handleFilterModelChange}
      hideFooterSelectedRowCount
      treeData={!!rest.getTreeDataPath}
      // density="compact"
    />
  );
}

// show {maxEntries}
function calculateDataGridHeight(contentArray?: DataGridRowsProp, maxVisibleEntries?: boolean | number) {
  if (maxVisibleEntries === undefined) {
    return "100%";
  } else {
    let numberOfRows = 1;
    if (contentArray && contentArray.length > 0) {
      const maxEntries = Number.isInteger(maxVisibleEntries) ? (maxVisibleEntries as number) : 5;
      numberOfRows = contentArray.length > maxEntries ? maxEntries : contentArray.length < 1 ? 1 : contentArray.length;
    }
    return `${numberOfRows * 50 + 125}px`;
  }
}

function renderHoverCopy(value: any, inlineAlignLeft?: boolean) {
  return (
    <CopyToClipoardHoverButton inline={inlineAlignLeft ? "leftAlign" : "rightAlign"} copyText={value}>
      {value}
    </CopyToClipoardHoverButton>
  );
}

interface CachedColumn {
  col: DataGridColumnDef;
  name: string;
  getValue: (row: any, col: DataGridColumnDef) => any;
}

class QuickSearchCache {
  private _columns: CachedColumn[];

  constructor(columns: DataGridColumnDef[]) {
    const rowValue = (row: any, col: DataGridColumnDef) => row[col.field];
    const getterValue = (row: any, col: DataGridColumnDef) =>
      col.valueGetter!({ row, value: rowValue(row, col) } as any);
    const formattedValue = (row: any, col: DataGridColumnDef) =>
      col.valueFormatter!({ row, value: rowValue(row, col) } as any);

    this._columns = columns.reduce((colList, col) => {
      if (col.filterable || col.filterable === undefined) {
        colList.push({
          col,
          name: col.field,
          getValue: col.valueFormatter ? formattedValue : col.valueGetter ? getterValue : rowValue
        });
      }

      return colList;
    }, [] as CachedColumn[]);
  }

  escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  filterRows(rows: any[], filter: string | undefined) {
    if (filter) {
      const start = Date.now();
      const searchRegex = new RegExp(this.escapeRegExp(filter), "i");

      const result = rows.filter((row: any) => this.filter(searchRegex, row));
      console.log("quicksearch", Date.now() - start, "ms", "columns:", this._columns.length);
      return result;
    } else {
      return rows;
    }
  }

  filter(search: RegExp, row: any) {
    return this._columns.some(col => {
      const value = col.getValue(row, col.col);

      if (value && typeof value !== "string" && typeof value !== "number") {
        // remove column
        this._columns = this._columns.filter(c => c !== col);
        return false;
      } else if (value) {
        return search.test(value.toString());
      } else {
        return false;
      }
    });
  }

  // filter2(search: RegExp, row: any) {
  //   return Object.keys(row).some(field => {
  //     const col = this._columns.find(col => col.name === field);
  //     if (col) {
  //       const value = col.getValue(row, col.col);

  //       // console.log("quicksearch-val", value);
  //       const include = value && (typeof value === "string" || typeof value === "number");
  //       if (include) {
  //         return search.test(value.toString());
  //       } else {
  //         return false;
  //       }
  //     }
  //   });
  // }
}

function CustomToolbar(opts: DataGridDefinition, showFilterButton?: boolean) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  let components: React.ReactNode[] = [];
  if (opts.showExportButton && !sm) {
    components.push(<GridToolbarExport key="export" />);
  }
  if (showFilterButton && !sm) {
    components.push(<GridToolbarFilterButton key="filter" />);
  }
  if (opts.customToolbarComponents) {
    components.push(opts.customToolbarComponents);
  }
  return <GridToolbarContainer>{components}</GridToolbarContainer>;
}

function CustomFooter(component: React.ReactNode, gridApi: GridApiRef) {
  let components: React.ReactNode[] = [component];
  components.push(<TotalRowsFooterComponent key="totalRows" gridApi={gridApi} />);
  // if (opts.showExportButton) {
  //   components.push(<GridToolbarExport key="export" />);
  // }
  // if (showFilterButton) {
  //   components.push(<GridToolbarFilterButton key="filter" />);
  // }
  // if (opts.customToolbarComponents) {
  //   components.push(opts.customToolbarComponents);
  // }
  return <GridFooterContainer>{components}</GridFooterContainer>;
}

export function TotalRowsFooterComponent(props: { gridApi: GridApiRef }) {
  const total = props.gridApi.current.getRowsCount();
  const visible = props.gridApi.current.getVisibleRowModels().size;
  return (
    <Box sx={{ marginX: 2 }}>
      <TextResource resKey={dataGridTextResources.footerTotalLabel} />{" "}
      {total === visible ? (
        total
      ) : (
        <>
          <span>{visible}</span> <TextResource resKey={dataGridTextResources.footerTotalPartText} />{" "}
          <span>{total}</span>
        </>
      )}
    </Box>
  );
}

export function GridIconItem(props: { icon: React.ReactElement; label?: string }) {
  return (
    <Box display="flex" alignItems="center">
      <Avatar sx={{ marginRight: 1 }}> {props.icon}</Avatar>
      {props.label}
    </Box>
  );
}
