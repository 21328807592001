import { ViewNames, buildTextResourcesMH, viewNamespace } from "../../core/textResourceScopes";

// export const AuctionTypeTextResources = buildTextResources4Enum<typeof AuctionType>({
//   scope: "Data",
//   namespace: enumNamespace(Domains.auction, "AuctionType"),
//   resources: {
//     bundle: "Bundle",
//     single: "Single",
//     dutch: "Dutch",
//     prospect: "Propsect",
//     spotbuy: "SpotBuy"
//   }
// });

export const AuctionDetailsTextResources = buildTextResourcesMH({
  scope: "Views",
  namespace: viewNamespace(ViewNames.auction, "ExAuctionDetails"),
  resources: {
    title: "Auction",
    auctionDetailsTile: "AuctionDetails",
    listPageTitle: "Auction List"
  }
});

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: viewNamespace(ViewNames.auction, "CreateAuction"),
  resources: {
    title: "Create Auction",

    auctionInfoString: "{{maker}} {{model}} -  {{vin}}",
    auctionInfoSubTitle: "{{dealerName}}",

    createSingleAuctionTitle: "Create Auction",

    createSingleAuctionInfoText:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
    createBundleAuctionTitle: "Create Bundle Auction",
    createProspectAuctionTitle: "Create Prospect Auction",
    createDutchAuctionTitle: "Create Dutch",
    createSpotBuyAuctionTitle: "Create Spotbuy Offer",
    createAuctionButton: "Create Auction",

    auctionParameterTitle: "Auction parameters",

    selectCarsButton: "Select Cars",
    selectedCarsButton: "Show Selected",
    selectBundleCarsTitle: "Select Cars for Bundle ({{count}})",
    selectedBundleCarsTitle: "Cars in Bundle ({{count}})",
    removeCarButton: "Remove Car",
    addCarButton: "Add Cars to Bundle",

    referencePriceTitle: "Reference Price",
    missingDataTitle: "Missing Data",
    missingDataInfoTitle: "Why?",
    missingDataInfoText: "To guarantee the best price we suggest you provide the following Data.",
    titleAuctionsInWindow: "Auction Timing",
    subTitleTimeframe: "Auctions in Timeframe",
    noAuctionsInTimeframe: "No competing auctions found. It's a perfect time to schedule yours!",
    errorGettingAuctionsText: "Error getting Auctions in Timeframe",
    // referencePriceText: "Average Price: {{avgPrice}}",
    referencePriceText: "Average Price on MotorHammer",
    includedCriteriaTitle: "Included Criteria",
    //  criteriaExplanationText: "Included Criteria",
    calculateReferencePriceButton: "Calculate Reference Price",
    referencePriceBasedOnText: "Based on {{refAuctionsCount}} Auctions",
    criteriaExplanationText: "Price calculated based on the following criteria:",
    showReferenceAuctionsButton: "show Auctions",

    //  field labels
    startPriceInputLabel: "Start Price",
    spotBuyPriceInputLabel: "SpotBuy Price",
    reservePriceInputLabel: "Reserve Price",

    priceRangeLabel: "Price Range",
    priceRangeInfo: "Alle {{stepDuration}} Sek. wird der Preis um {{step}} € reduziert.",
    priceRangeWarning: "The Target Price must be at least 100€.",

    prospectDateLabel: "Prospect Date",

    durationSelectLabel: "Duration",
    durationMenuItemMinutesLabel: "{{min}} minutes",
    durationMenuItemHoursLabel: "{{std}} hours",

    // timeline

    ownAuctionItemLabel: "Your Auction",

    // reference Price
    averagePriceOnMHTitle: "Average Price on MotorHammer",
    basedOnXAuctionsLabel: "Based on {{count}} Auctions",
    noReferenceFoundLabel: "No References found",
    avaragePriceLabel: "Average Price: {{price}}",
    startPriceLabel: "Start Price: {{startPrice}}",
    highestBidLabel: "Highest Bid: {{highestBid}}",

    // Validations

    //  "maker",
    // "model",
    // "vintage",
    // "mileage",
    // "tuv",
    // "fuel",
    // "checkbook",
    // "tax",
    // "previousOwner",
    // "displacement",
    // "hp",
    // "kw",
    // "accident",
    // "images"
    missingField_maker: "Marke",
    missingField_model: "Model",
    missingField_vintage: "Vintage",
    missingField_milage: "",
    missingField_tuv: "",
    missingField_fuel: "",
    missingField_checkbook: "",
    missingField_tax: "",
    missingField_displacement: "",

    annotationDialogTitle: "Annotation",
    btnCancel: "Cancel",
    btnSave: "Save Annotation",
    byLabel: "by",
    annotationsTitle: "Seller's notes",

    newNoteButton: "New Note",
    newNoteButtonInfo: "Add a note to the auction.",
    confirmationDialogTitle: "Delete Note",
    confirmationDialogMessage: "Do you really want to delete the note?"
  }
});

export const ExAucitonLisTextResources = buildTextResourcesMH({
  scope: "Views",
  namespace: viewNamespace(ViewNames.auction, "ExAuctionList"),
  resources: {
    title: "Auction List",
    startPriceLabel: "Startprice",
    highestBidLabel: "Highest Bid"
  }
});
