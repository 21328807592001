import { AuctionState, AuctionType, SecurityAttributeScope, UserType } from "@mh/common";
import { IAuctionDetailsUI } from "../data/auction/AuctionDetails";

export function getSecurityAttributeScope(userType?: UserType) {
  if (userType === UserType.Dealer || userType === UserType.Admin || userType === UserType.System_Admin) {
    return SecurityAttributeScope.max;
  } else {
    return SecurityAttributeScope.min;
  }
}

export const isProspectingOrDutch = (auction: IAuctionDetailsUI) => {
  return auction && (auction.auctionType === AuctionType.dutch || auction.auctionType === AuctionType.prospect);
};

export const hasAuctionEnded = (auction: IAuctionDetailsUI) => {
  return auction && (auction.auctionEndTime < Date.now() || auction.state === AuctionState.ended);
};
