import { buildSelectOptions, dataKitBuilder } from "@emibee/lib-app-common";
// import { Dealer } from "../dealer";
import { DateRangeFetchMoreArgs } from "@emibee/lib-shared";
import { AuctionType, GetAuctionListArgs, GetSalesAuctionsByVinArgs } from "@mh/common";
import { AuctionListItemDataKit, IAuctionListItemUI, auctionTypeTextResources } from "./AuctionListItem";
import { ImageCell } from "../../controls/ImageCell";

export interface SalesAuctionListItem extends IAuctionListItemUI {
  auctionStartTime: number;

  // keyAccountName?: string;
  isSold?: boolean;
}

const salesAuctionListTextResources = AuctionListItemDataKit.extendTextResources<SalesAuctionListItem>({
  auctionStartTime: "Started At",
  // keyAccountName: "Key Account",
  isSold: "sold"
});

export const SalesAuctionListDataKit = dataKitBuilder(salesAuctionListTextResources)
  .fieldType("dateTime", "auctionEndTime", "createdAt", "auctionStartTime")
  .field("auctionType")
  .props({
    options: buildSelectOptions(AuctionType, auctionTypeTextResources)
  })
  // .fieldType("dateTime", "auctionEndTime", "createdAt")
  // .field("mediaFiles")
  // .props({ complex: MediaFileDataKit })
  .field("auctionType")
  .props({
    options: buildSelectOptions(AuctionType, auctionTypeTextResources)
  })
  .virtualField("vVin")
  .props({
    valueGetter(record) {
      return record.car?.vin;
    }
  })
  .virtualField("vMaker")
  .props({
    valueGetter(record) {
      return record.car?.maker;
    }
  })
  .virtualField("vModel")
  .props({
    valueGetter(record) {
      return record.car?.model;
    }
  })
  .virtualField("isSold")
  .props({
    valueGetter(record) {
      // console.log("🚀 ~ valueGetter ~ record:", record);
      // const startPrice = record.startPrice ? record.startPrice : 0;
      // const lastBid = record.lastBid ? record.lastBid.amount : 0;
      // const isSold =
      //   (record.auctionType === AuctionType.single || record.auctionType === AuctionType.bundle) &&
      //   lastBid > startPrice;

      // return (
      //   record.isProspectDeal === true ||
      //   ((record.auctionType === AuctionType.single || record.auctionType === AuctionType.bundle) &&
      //     lastBid > startPrice)
      // );
      const startPrice = record.startPrice ?? 0;
      const lastBid = record.lastBid?.amount ?? 0;
      let returnVal: boolean | undefined;
      if (record.auctionType === AuctionType.single || record.auctionType === AuctionType.bundle) {
        if (lastBid > startPrice) {
          returnVal = true;
        } else {
          returnVal = false;
        }
      } else {
        returnVal = record.isProspectDeal;
      }
      return returnVal;
    }
  })
  .virtualField("vVisitorCount")
  .props({
    valueGetter(record) {
      return record.statistics?.visitorCount;
    }
  })
  .virtualField("vStarredCount")
  .props({
    valueGetter(record) {
      return record.statistics?.starredCount;
    }
  })
  .field("mainImage")
  .props({
    valueFormatter(mainImage) {
      // console.log("🚀 ~ valueFormatter ~ mainImage:", mainImage);
      return ImageCell({ image: mainImage, width: 80, height: 60 });
    }
  })

  // .field("_highestBidder")
  // .props({ complex: UserDetailsDataKit })
  .queries(qb => ({
    getSalesAuctionList: qb
      .query("getAuctionList")
      .args<DateRangeFetchMoreArgs<GetAuctionListArgs>>("GetAuctionListArgs", true)
      .incrementalFetch("DateRange")
      // .ListResult.allFields(true)
      .ListResult.selectFields(
        "id",
        "auctionEndTime",
        "auctionStartTime",
        "auctionType",
        "mainImage",
        "lastBid",
        "isProspectDeal",
        "statistics",
        "reservePrice",
        "startPrice",
        "targetPrice",
        "ownerOrgName",
        "createdAt",
        "keyAccountName"
      )
      .subFields("statistics", "visitorCount", "starredCount")
      .subFields("car", "vin", "maker", "model")
      .subFields("mainImage", "uri", "mediaType", "v2")
      .subFields("lastBid", "amount")
      .subFields("bidderInfo", "bidderDisplayName")
      .build(),
    getSalesAuctionsByVin: qb
      .query("getSalesAuctionsByVin")
      .args<GetSalesAuctionsByVinArgs>("GetSalesAuctionsByVinArgs", true)
      // .ListResult.allFields(true)
      .ListResult.selectFields(
        "id",
        "auctionEndTime",
        "auctionStartTime",
        "auctionType",
        "mainImage",
        "lastBid",
        "isProspectDeal",
        "statistics",
        "reservePrice",
        "startPrice",
        "targetPrice",
        "ownerOrgName",
        "createdAt"
      )
      // .subFields("mediaFiles", "uri", "name")
      .subFields("car", "vin", "maker", "model")
      .subFields("mainImage", "uri", "mediaType", "v2")
      .subFields("lastBid", "amount")
      .subFields("bidderInfo", "bidderDisplayName")
      .subFields("statistics", "visitorCount", "starredCount")
      // .subBuilder("_highestBidder", builder => {
      //   builder.selectFields("id", "firstname", "lastname", "username", "email", "phone");
      // })
      // .subFields("images", "isMainImage", "thumb", "src")
      .build()
  }))
  .build();
