import {
  buildSelectOptions,
  buildTextResources4Data,
  buildTextResources4Enum,
  dataKitBuilder,
  enumNamespace
} from "@emibee/lib-app-common";
import { AuctionType, AuctionsInWindowArgs, CarMediaFile, GetAuctionListArgs, IAuctionGraph } from "@mh/common";

import { DateRangeFetchMoreArgs } from "@emibee/lib-shared";
import { SearchAuctionsArgs } from "@mh/common";
import { Domains } from "../../core/textResourceScopes";
import { dateSorter } from "../../tools/utils";
import { MediaFileDataKit } from "../car/CarMin";
import { ImageCell } from "../../controls/ImageCell";
// export interface AuctionListItem extends AuctionMin {
//   dealerId?: string;
//   dealerName?: string;
//   hasAuctionEnded: boolean;
//   startBid: number;
//   highestBid: number;
//   _highestBidder?: UserDetails;
//   auctionEndTime: number;
//   images?: CarImage[];
//   mainImage?: CarImage;
// reservePrice?: number;
// targetPrice?: number;
// isProspectDeal?: boolean;
// }
export const auctionTypeTextResources = buildTextResources4Enum<typeof AuctionType>({
  scope: "Data",
  namespace: enumNamespace(Domains.auction, "AuctionType"),
  resources: {
    bundle: "Bundle",
    single: "Auction",
    prospect: "Prospect",
    dutch: "Dutch",
    spotbuy: "Spot Buy"
  }
});

export type IAuctionListItemUI = Pick<
  IAuctionGraph,
  | "id"
  | "auctionType"
  | "auctionEndTime"
  | "targetPrice"
  | "reservePrice"
  | "isProspectDeal"
  | "startPrice"
  | "mainImage"
  | "ownerOrgName"
  | "lastBid"
  | "bidderInfo"
  | "statistics"
  | "car"
  | "createdAt"
  | "keyAccountName"
> & {
  readonly vVin?: string;
  readonly vMaker?: string;
  readonly vModel?: string;
  readonly vLastBid?: number;
  readonly vLastBidderName?: string;
  readonly vVisitorCount?: number;
  readonly vStarredCount?: number;
};

const auctionListItemTextResources = buildTextResources4Data<IAuctionListItemUI>({
  scope: "Data",
  namespace: "Auction",
  resources: {
    // dealerName: "DealerName",
    // dealerId: "Dealer",
    id: "ID",
    vVin: "Vin",
    vMaker: "Maker",
    vModel: "Model",
    auctionType: "Type",
    auctionEndTime: "Endtime",
    startPrice: "Start Price",

    vLastBid: "Highest bid",
    vLastBidderName: "Highest Bidder",
    vStarredCount: "StarredBy",
    vVisitorCount: "Visitors",
    statistics: "Statistics",
    mainImage: "Main Image",
    // hasAuctionEnded: "auctionEnded",
    // _highestBidder: "Highest Bidder",
    // images: "Images",
    // mainImage: "Main Image",
    reservePrice: "Reserve Price",
    targetPrice: "Target Price",
    isProspectDeal: "Deal/noDeal",
    ownerOrgName: "Organization",
    car: "Car",
    lastBid: "Last bid",
    bidderInfo: "Bidder Info",
    createdAt: "Created At",
    keyAccountName: "Key Account"
  }
});

export const AuctionListItemDataKit = dataKitBuilder(auctionListItemTextResources)
  .fieldType("dateTime", "auctionEndTime")
  .field("auctionType")
  .props({
    options: buildSelectOptions(AuctionType, auctionTypeTextResources)
  })
  .virtualField("vVin")
  .props({
    valueGetter(record) {
      return record.car?.vin;
    }
  })
  .virtualField("vMaker")
  .props({
    valueGetter(record) {
      return record.car?.maker;
    }
  })
  .virtualField("vModel")
  .props({
    valueGetter(record) {
      return record.car?.model;
    }
  })
  .virtualField("vLastBid")
  .props({
    valueGetter(record) {
      return record.lastBid?.amount ?? null;
    }
  })
  .virtualField("vLastBidderName")
  .props({
    valueGetter(record) {
      return record.bidderInfo?.bidderDisplayName ?? undefined;
    }
  })
  .virtualField("vVisitorCount")
  .props({
    valueGetter(record) {
      return record.statistics?.visitorCount;
    }
  })
  .virtualField("vStarredCount")
  .props({
    valueGetter(record) {
      return record.statistics?.starredCount;
    }
  })
  .field("mainImage")
  .props({
    valueFormatter(mainImage) {
      return ImageCell({ image: mainImage, width: 80, height: 60 });
    }
  })

  // .field("_highestBidder")
  // .props({ complex: UserMinDataKit })
  // .field("images")
  // .props({ complex: MediaFileDataKit })
  // .field("mediaFiles")
  // .props({ complex: MediaFileDataKit })
  .queries(qb => ({
    getAuctionList: qb
      .query("getAuctionList", { includeCV: true })
      .args<DateRangeFetchMoreArgs<GetAuctionListArgs>>("GetAuctionListArgs", true)
      .incrementalFetch("DateRange")
      .ListResult.selectFields(
        "auctionEndTime",
        "auctionType",
        // "dealerId",
        // "dealerName",
        // "hasAuctionEnded",
        "mainImage",
        "lastBid",
        "bidderInfo",
        "isProspectDeal",
        "id",
        "reservePrice",
        // "startBid",
        "startPrice",
        "targetPrice",
        "ownerOrgName",
        "createdAt",
        "keyAccountName"
      )
      // .subBuilder("_highestBidder", builder => {
      //   builder.selectFields("vDisplayName");
      // })
      .subFields("statistics", "visitorCount", "starredCount")
      .subFields("mainImage", "uri", "mediaType", "v2")
      .subFields("car", "vin", "maker", "model")

      .subFields("bidderInfo", "bidderId", "bidderDisplayName")
      .subFields("lastBid", "id", "amount", "count", "createdAt")
      .build(),
    searchAuction: qb
      .query("searchAuctions", { includeCV: true })
      .args<SearchAuctionsArgs>("SearchAuctionsArgs", true)
      // .ListResult.allFields(true)
      .ListResult.selectFields(
        "auctionEndTime",
        "auctionType",
        // "dealerId",
        // "dealerName",
        // "hasAuctionEnded",
        "mainImage",
        "lastBid",
        "isProspectDeal",
        "id",
        "car",
        "reservePrice",
        // "startBid",
        "startPrice",
        "targetPrice",
        "ownerOrgName",
        "createdAt"
      )
      .subFields("statistics", "visitorCount", "starredCount")
      .subFields("mainImage", "uri", "mediaType", "v2")
      .subFields("car", "vin", "maker", "model")

      .subFields("lastBid", "amount")
      .subFields("bidderInfo", "bidderId", "bidderDisplayName")
      .build(),
    getAuctionsInWindow: qb
      .query("getAuctionsInWindow")
      .args<AuctionsInWindowArgs>("AuctionsInWindowArgs", true)
      .ListResult.selectFields("id", "auctionEndTime")
      .subFields("car", "vin", "maker", "model")
      .subFields("lastBid", "amount")
      .subFields("bidderInfo", "bidderId", "bidderDisplayName")
      .build()

    // getAuctionsByVin: qb
    //   .query("getAuctionsByVin")
    //   .args<GetSalesAuctionsByVinArgs>("GetSalesAuctionsByVinArgs", true)
    //   .ListResult.allFields(true)
    //   .subBuilder("_highestBidder", builder => {
    //     builder.selectFields("id", "firstname", "lastname", "username", "email", "phone");
    //   })
    //   .subFields("images", "isMainImage", "thumb", "src")
    //   .build()
  }))
  .build();

// Default direction is DESC
export function sortAuctionsByAuctionEndtime(
  auctions?: IAuctionListItemUI[],
  direction?: "ASC" | "DESC"
): IAuctionListItemUI[] {
  if (auctions) {
    return auctions.sort(dateSorter("auctionEndTime", direction ?? "DESC"));
  } else return [];
}
