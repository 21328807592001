import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps } from "@emibee/lib-app-common";
import { RwfPermission } from "@emibee/lib-shared";
import { ICarV2, RwfPrivilegeMH } from "@mh/common";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { buildDateRangeFetchMoreArgsFromPreset } from "../../controls/view/useFetchMoreFilter";
import { defaults, Domains, domainView } from "../../core/textResourceScopes";
import { CarDetailsDataKit } from "../../data/car/CarDetails";
import { CarListItem, CarListItemDataKit } from "../../data/car/CarListItem";
import { textResources } from "./textResources";
import { KeepAliveOwner } from "../../core/MHClientService";

export interface CarDetailsConcurrentProps extends PageConcurrentPrefetchProps<ICarV2> {
  carId: string;
}

// TODO: Wo wird die verwendet? Antwort: wenn man eine URL shared... dann kommt man direkt auf die Detail Page
export const CarDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/carDetails",
  displayText: textResources.detailsPageTitle,
  icon: <DirectionsCarIcon />,
  auth: true,
  resources: {
    lazy: () => import("./CarDetailsPage"),
    localeNamespaces: [domainView(Domains.car), defaults()],
    prefetchGraph: (args?: CarDetailsConcurrentProps) => ({
      query: CarDetailsDataKit.queries.getCarDetails,
      args: { carId: args?.carId }
      // watchQuery: true
    })
  },
  params: ["carId"],
  privilege: RwfPrivilegeMH.Car,
  permission: RwfPermission.full
});

export type CarListConcurrentProps = PageConcurrentPrefetchProps<CarListItem[]>;

// export interface CreateCarPageProps {
//   vin?: string;
// }

// export const CreateCarRoute = buildRouteWithParams<CreateCarPageProps>({
//   path: "/createCar",
//   auth: true,
//   resources: {
//     component: CreateCarPage,
//     localeNamespaces: [domainView(Domains.car), defaults()]
//   },
//   privilege: Privileges.CreateCars
// });

export const CarListRoute = buildRouteWithGraphPrefetch({
  path: "/carList",
  icon: <DirectionsCarIcon />,
  displayText: textResources.carListPageTitle,
  auth: true,
  resources: {
    lazy: () => import("./CarListPage"),
    localeNamespaces: [domainView(Domains.car), defaults()],
    prefetchGraph: () => ({
      query: CarListItemDataKit.queries.getCarList,
      args: CarListItemDataKit.buildDateRangeFetchMoreArgs(
        "createdAt",
        buildDateRangeFetchMoreArgsFromPreset({ latestDays: 14 }),
        {}
      ),
      watchQuery: true,
      keepAlive: KeepAliveOwner.CarList
    })
  },
  privilege: RwfPrivilegeMH.Car,
  permission: RwfPermission.full
});
