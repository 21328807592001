/**
 * UserInfo
 */

import { buildGraphCrudQuerySet } from "@emibee/lib-app-common";
import { GetAccountArgs } from "@emibee/lib-shared";
import { CheckAddressArgs, CheckAddressResult } from "../../pages/account/useAddressValidate";
import { UniqueNameFields } from "../../pages/account/useUniqueNameValidatorBuilder";
import { UserOverview, UserOverviewDataKit } from "./UserOverview";

export interface UserInfo extends UserOverview {
  // company?: Company;
  // _files?: IFileRef[];
}

const userInfoTextResources = UserOverviewDataKit.extendTextResources<UserInfo>({
  title: "Title"
  // _files: "Files",
  // company: "Company"
});

export enum PersonalTitle {
  Mr = 0,
  Ms = 1
}

export interface Location {
  type: string;
  coordinates: [number, number];
}

export interface Company {
  name: string;
  vatId: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  countryCode: string;
  location: string;
  state: string;
  stateCode: string;
  formatted_address: string;
}

export interface ChangeAccountPersonalDataArgs extends Partial<UserInfo> {
  id: string;
}

export interface UniqueNameArgs {
  field?: string;
  fieldType?: keyof UniqueNameFields;
}

export const UserInfoDataKit = UserOverviewDataKit.extend(userInfoTextResources)

  .queries(qb => ({
    getAccount: qb
      .query("getAccount")
      .args<GetAccountArgs>("GetAccountInput", true)
      .Result.allFields(true)
      .build(),
    isUniqueName: qb
      .query("isUniqueName")
      .args<UniqueNameArgs>("UniqueNameInput", true)
      .PrimitiveResult<Boolean>()
      .build(),
    changePersonalData: qb
      .mutation("changeAccountPersonalData")
      .args<ChangeAccountPersonalDataArgs>("ChangeAccountPersonalDataInput", true)
      .extractArgFields("id", "firstname", "lastname", "title", "email", "phone")
      .Result.allFields(true)
      .build(),
    // GoogleApiTest
    checkAddress: qb
      .query("checkAddress")
      .args<CheckAddressArgs>("CheckAddressInput", true)
      .Result.customType<CheckAddressResult>()
      .selectFields("street", "zip", "city", "state", "stateCode", "country", "countryCode", "formatted_address")
      .subFields("location", "coordinates")
      .build()
  }))
  .build();

export const userInfoGraphQuerySet = buildGraphCrudQuerySet(
  UserInfoDataKit.queries.getAccount,
  UserInfoDataKit.queries.changePersonalData
);
